import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

function SEO({
  url,
  title,
  titleTemplate,
  description,
  lang = `en`,
  meta = [],
  keywords = [],
  opengraph = {},
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      defer={false}
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={titleTemplate || `%s • ${site.siteMetadata.title}`}
      defaultTitle={site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        url && {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:title`,
          content: title ?? site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: opengraph.type ?? 'article',
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}/avatar.png`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: `@${site.siteMetadata.social.twitter}`,
        },
        {
          name: `twitter:creator`,
          content: `@${site.siteMetadata.social.twitter}`,
        },
      ]
        .concat(meta)
        .concat(
          keywords.length
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .filter(Boolean)}
      link={[
        url && {
          rel: 'canonical',
          href: url,
        },
      ].filter(Boolean)}
    />
  )
}

export default SEO
